export function Content(){
    return(
        <div>
            <h1>
                Bu Sayfa Yapım Aşamasında
            </h1>
            <p>
                Üzgünüz, bu sayfa yapım aşamasında. Lütfen daha sonra tekrar deneyin.
            </p>
        </div>
    )
}